// extracted by mini-css-extract-plugin
export var alignLeft = "l_pB d_bG";
export var alignCenter = "l_bP d_bD";
export var alignRight = "l_pC d_bH";
export var textAlignLeft = "l_pD";
export var textAlignCenter = "l_pF";
export var textAlignRight = "l_pG";
export var embedInnerWrapperDesign1 = "l_pH d_bM";
export var embed = "l_pJ d_b1";
export var titleMargin = "l_pK d_cw d_dw";
export var subtitleMargin = "l_pL d_cs d_dw";
export var paragraphMargin = "l_pM d_cw d_dw";
export var SubtitleSmall = "l_pN q_pN q_qH q_qT";
export var SubtitleNormal = "l_pP q_pP q_qH q_qV";
export var SubtitleLarge = "l_pQ q_pQ q_qH q_qW";
export var BodySmall = "l_pR q_pR q_qH q_q0";
export var BodyNormal = "l_pS q_pS q_qH q_q1";
export var BodyLarge = "l_pT q_pT q_qH q_q2";