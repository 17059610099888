// extracted by mini-css-extract-plugin
export var alignLeft = "v_pB d_bG";
export var alignCenter = "v_bP d_bD";
export var alignRight = "v_pC d_bH";
export var textAlignLeft = "v_pD";
export var textAlignCenter = "v_pF";
export var textAlignRight = "v_pG";
export var hoursInnerWrapperAlt = "v_th d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "v_tj d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "v_tk d_dw";
export var titleMargin = "v_pK d_cw";
export var hoursInnerInnerWrapper = "v_tl d_cz";