// extracted by mini-css-extract-plugin
export var alignLeft = "w_pB d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_pC d_fr d_bH d_dx";
export var alignColumnLeft = "w_tm d_fs d_bN d_dv";
export var alignColumnCenter = "w_tn d_ft d_bP d_dw";
export var alignColumnRight = "w_tp d_fv d_bQ d_dx";
export var milestonesContainer = "w_tq d_dW";
export var milestonesContainerFull = "w_tr d_dT";
export var milestonesSubtitle = "w_mf d_mf d_cs";
export var milestonesComponentWrapper = "w_mb d_mb d_cv";
export var compContentWrapper = "w_ts d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "w_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "w_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "w_tt d_H";
export var milestonesBlockWrapper = "w_tv";
export var milestonesTextBlockWrapper = "w_tw";
export var milestonesComponentHeader = "w_md d_md d_w d_cr";
export var milestonesComponentParagraph = "w_mj d_mj d_w";
export var btnWrapper = "w_d2 d_bz d_ck";
export var btnWrapperCards = "w_tx d_bz d_cd";
export var imageWrapper = "w_ty d_cs d_bz";
export var Title1Small = "w_rb q_rb q_qH q_qJ";
export var Title1Normal = "w_rc q_rc q_qH q_qK";
export var Title1Large = "w_rd q_rd q_qH q_qL";
export var Title2Small = "w_rf q_rf q_qH q_qM";
export var Title2Normal = "w_rg q_rg q_qH q_qN";
export var Title2Large = "w_rh q_rh q_qH q_qP";
export var SubtitleSmall = "w_pN q_pN q_qH q_qT";
export var SubtitleNormal = "w_pP q_pP q_qH q_qV";
export var SubtitleLarge = "w_pQ q_pQ q_qH q_qW";
export var Subtitle2Small = "w_rm q_rm q_qH q_qX";
export var Subtitle2Normal = "w_rn q_rn q_qH q_qY";
export var Subtitle2Large = "w_rp q_rp q_qH q_qZ";
export var BodySmall = "w_pR q_pR q_qH q_q0";
export var BodyNormal = "w_pS q_pS q_qH q_q1";
export var BodyLarge = "w_pT q_pT q_qH q_q2";
export var exceptionLineHeight = "w_tz q_q8";