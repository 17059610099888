// extracted by mini-css-extract-plugin
export var socialContainer = "x_tB";
export var instagramLink = "x_tC";
export var socialWrapperLeft = "x_nX d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "x_tD";
export var socialContentContainerFull = "x_tF";
export var instagramContainer = "x_tG";
export var twitterContainer = "x_tH";
export var facebookContainer = "x_tJ";
export var socialErrorContainer = "x_tK";
export var facebookContainerWide = "x_tL";
export var twitterContainerWide = "x_tM";
export var socialParagraphCenter = "x_tN";
export var instaWrapper = "x_tP";
export var SubtitleSmall = "x_pN q_pN q_qH q_qT";
export var SubtitleNormal = "x_pP q_pP q_qH q_qV";
export var SubtitleLarge = "x_pQ q_pQ q_qH q_qW";
export var textLeft = "x_dv";
export var textCenter = "x_dw";
export var textRight = "x_dx";